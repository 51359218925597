import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

function Section(props) {
  const { title, children } = props;

  return (
    <section id={title.replace(/ /g, '-').toLowerCase()} className="app-section">
      <div className="container grid-4-1">
        <h2 className="col-1-4">{title}</h2>
        {children}
      </div>
    </section>
  );
}

Section.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.instanceOf(Node).isRequired,
};

export default Section;
