import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

function Button(props) {
  const {
    label, round, secondary, onClick,
  } = props;

  return (
    <button type="button" className={`${round ? 'round' : ''} ${secondary ? 'secondary' : ''}`} onClick={onClick}>
      { label }
    </button>
  );
}

Button.defaultProps = {
  round: false,
  secondary: false,
};

Button.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  round: PropTypes.bool,
  secondary: PropTypes.bool,
};

export default Button;
