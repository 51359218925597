import React from 'react';
import './index.scss';

import SittingLaptop from '../../images/sitting-laptop.jpg';
import Button from '../Button';

function Header() {
  return (
    <header>
      <div className="container grid-2-1">
        <h1 className="col-1-2">Espinel</h1>

        <div className="col-1-2 col-md-1-1 align-self-center justify-self-center">
          <div className="blurb-container">
            <strong>Business is Now Digital</strong>
            <small>We blend insights and stragetgy to create digital products for forward thinking organisations</small>
            <Button label="Go do this thing" round />
          </div>
        </div>

        <div className="col-2-2 align-self-center justify-self-center hide-md-down">
          <img src={SittingLaptop} alt="Woman sitting on the floow with laptop" />
        </div>
      </div>
    </header>
  );
}

Header.propTypes = {

};

export default Header;
