import React from 'react';
import PropTypes from 'prop-types';
import './WorksItem.scss';

import TeamConcept from '../../images/team-concept.jpg';
import ComingSoon from '../../images/coming-soon.png';

function WorksItem(props) {
  const {
    dark,
    title,
    image,
    children,
    comingSoon,
  } = props;

  return (
    <div className={`works-item ${dark ? 'dark' : ''}`}>
      {comingSoon && <img src={ComingSoon} className="coming-soon" alt="coming soon" />}
      <div className="image-container hide-md-down" style={{ backgroundImage: `url(${image})` }} />
      <h3>{title}</h3>
      {children}
    </div>
  );
}

WorksItem.defaultProps = {
  dark: false,
  image: TeamConcept,
  comingSoon: false,
};

WorksItem.propTypes = {
  dark: PropTypes.bool,
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  children: PropTypes.instanceOf(Node).isRequired,
  comingSoon: PropTypes.bool,
};

export default WorksItem;
