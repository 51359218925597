import React from 'react';

import WorksItem from './WorksItem';
import JobSentry from '../../images/projects/jobsentry/magnify-label.jpg';

function FeaturedWorks() {
  return (
    <>
      <WorksItem dark title="Job Sentry" image={JobSentry}>
        <p>
          Nullam id dolor id nibh ultricies vehicula ut id elit. Nulla vitae elit libero, a pharetra augue. Nullam quis risus eget urna mollis ornare vel eu leo. Nulla vitae elit libero, a pharetra augue. Integer posuere erat a ante venenatis dapibus posuere velit aliquet.
        </p>

        <p><a href="https://www.jobsentry.co.uk" target="_blank" rel="noopener noreferrer">www.jobsentry.co.uk</a></p>
      </WorksItem>

      <WorksItem dark title="Gills Thing" comingSoon>
        <p>
          Nullam id dolor id nibh ultricies vehicula ut id elit. Nulla vitae elit libero, a pharetra augue. Nullam quis risus eget urna mollis ornare vel eu leo. Nulla vitae elit libero, a pharetra augue. Integer posuere erat a ante venenatis dapibus posuere velit aliquet.
        </p>
      </WorksItem>
    </>
  );
}

FeaturedWorks.propTypes = {

};

export default FeaturedWorks;
