import React from 'react';

function Footer() {
  return (
    <footer>
      This is the footer <a href="https://www.freepik.com/free-photos-vectors/business">Business vector created by freepik - www.freepik.com</a>
    </footer>
  );
}

Footer.propTypes = {

};

export default Footer;
