import React from 'react';
import PropTypes from 'prop-types';
import './ServiceItem.scss';

function ServiceItem(props) {
  const {
    title,
    image,
    children,
    dark,
  } = props;
  return (
    <div className={`service-item ${dark ? 'dark' : ''}`}>
      <img src={image} width="100%" alt={title} className="hide-sm-down" />
      <h3>{title}</h3>
      {children}
    </div>
  );
}

ServiceItem.defaultProps = {
  dark: false,
};

ServiceItem.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  children: PropTypes.instanceOf(Node).isRequired,
  dark: PropTypes.bool,
};

export default ServiceItem;
