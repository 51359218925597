import React from 'react';
import './index.scss';

import ServiceItem from './ServiceItem';
import Programming from '../../images/programming.jpg';
import Charts from '../../images/analyse-charts.jpg';
import Products from '../../images/products.jpg';
import Architecture from '../../images/architecture.jpg';

function OurServices() {
  return (
    <>
      <p className="intro col-1-4">
        Donec id elit non mi porta gravida at eget metus. Donec ullamcorper nulla non metus auctor fringilla. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Integer posuere erat a ante venenatis dapibus posuere velit aliquet.
      </p>
      <div className="col-1-4 grid-4-1">
        <ServiceItem title="Business Architecture" image={Architecture}>
          <p>Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Sed posuere consectetur est at lobortis.</p>
        </ServiceItem>

        <ServiceItem title="Business Analasys" image={Charts}>
          <p>Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.</p>
        </ServiceItem>

        <ServiceItem title="Development" image={Programming}>
          <p>
            Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Aenean lacinia bibendum nulla sed consectetur. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
          </p>
        </ServiceItem>

        <ServiceItem title="Products" image={Products}>
          <p>Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Aenean lacinia bibendum nulla sed consectetur. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
        </ServiceItem>
      </div>
    </>
  );
}

OurServices.propTypes = {

};

export default OurServices;
