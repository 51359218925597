import React from 'react';

import Header from './components/Header';
import Section from './components/Section';
import OurServices from './sections/OurServices';
import FeaturedWorks from './sections/FeaturedWorks';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <Header />

      <Section title="Our Services">
        <OurServices />
      </Section>

      <Section title="Featured Works">
        <FeaturedWorks />
      </Section>

      <Section title="Meet the team">
        Meet The Team Section
      </Section>

      <Section title="Contact us">
        Contact Section
      </Section>

      <Footer />
    </div>
  );
}

export default App;
